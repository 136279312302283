import { environment } from '@roadrecord/environment';
import { attachAction } from '@ngxs-labs/attach-action';
// tslint:disable-next-line:nx-enforce-module-boundaries
import { ShowOnSaleEndDateNotificationAction } from '../../../common/src/lib/action/show-on-sale-end-date-notification.action';
// tslint:disable-next-line:nx-enforce-module-boundaries
import { CompanyContextStateModel } from '../../../common/src/lib/model/company-context-state.model';
import { StateContext } from '@ngxs/store';
import { commonHttpStreamErrorHandler } from '@roadrecord/utils';
import { HttpClient } from '@angular/common/http';
import moment from 'moment';

export function attachCompanyStateContextDynamicActions(storeClass: any, http: HttpClient) {
  attachAction(storeClass, ShowOnSaleEndDateNotificationAction, (ctx: StateContext<CompanyContextStateModel>) => {
    const { subscription } = ctx.getState();

    ctx.dispatch({
      type: '[Sticky notification] show',
      option: {
        text: 'COMPANY_CONTEXT.NOTIFICATION.ON_SALE_END_DATE',
        textTranslateParams: {
          url: '/pricing/on-sale/',
          date: moment(subscription.on_sale_end_date).format('MM/DD/YYYY'),
        },
        hasCloseButton: true,
        showCountDownTimer: true,
      },
    });
  });
}

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppLayoutComponent, HasAccesMenuItemGuard } from '@roadrecord/app-layout/common';
import { MustFirstStepGuard, NoNeedFirstStepGuard } from '@roadrecord/first-steps/common';
import { MobileDetectGuard } from '@roadrecord/mobile-detect';
import { PartnerTypeEnum } from '@roadrecord/partner/model';
import {
  APPLICATION_SETTINGS,
  BASIC_DATA_PAGE_PATH,
  CHANGE_PASSWORD,
  CHARGING_STATION_ROUTE_PATH,
  COMPANY_MEMBER,
  DAILY_PERMANENT_DESTINATION_PATH,
  DEFAULT_ROUTE_PATH,
  EXPENSE_ROUTH_PATH,
  FINALIZATION_OF_ROUTE_ROUTE_PATH,
  FIRST_STEPS_PATH,
  FUELING_ROUTE_PATH,
  HEAD_OFFICE_OR_SITE_PATH,
  MILEAGE_ROUTE_PATH,
  MONTH_ACTIVITIES_PAGE_PATH,
  PARTNER_DISTANCE_ROUTE_PATH,
  PARTNER_ROUTE_PATH,
  PRICE_SITE_PATH,
  RECOMMENDATION_ROUTE_PATH,
  REPORT_PRINT_PATH,
  REPORT_SITE_PATH,
  SPECIAL_DAYS_ROUTE_PATH,
  STANDARD_MILEAGE_RATE_ROUTE_PATH,
  SUBSCRIPTION_DATA,
  TRIAL_TIME_ROUTE_PATH,
  TRIP_REASON_ROUTE_PATH,
  USER_ROUTE_PATH,
  VEHICLE_ROUTE_PATH,
} from '@roadrecord/common/common';
import { AuthGuard } from '@roadrecord/user/common';

const routes: Routes = [
  { path: '', redirectTo: DEFAULT_ROUTE_PATH, pathMatch: 'full' },
  {
    path: '',
    loadChildren: () => import('@roadrecord/user/common').then(m => m.UserModule),
  },
  {
    path: PRICE_SITE_PATH,
    loadChildren: () => import('@roadrecord/user/common').then(m => m.PriceModule),
  },
  {
    path: '',
    component: AppLayoutComponent,
    canActivateChild: [MobileDetectGuard, AuthGuard],
    canLoad: [AuthGuard],
    children: [
      {
        path: FIRST_STEPS_PATH,
        loadChildren: () => import('@roadrecord/first-steps/common').then(m => m.FirstStepsModule),
        canLoad: [AuthGuard],
        canActivate: [AuthGuard, NoNeedFirstStepGuard],
      },
      {
        path: BASIC_DATA_PAGE_PATH,
        canActivateChild: [MustFirstStepGuard],
        children: [
          { path: '', redirectTo: PARTNER_ROUTE_PATH, pathMatch: 'full' },
          {
            path: TRIP_REASON_ROUTE_PATH,
            loadChildren: () => import('@roadrecord/trip-reason/common').then(m => m.TripReasonModule),
            canLoad: [AuthGuard],
            canActivate: [AuthGuard],
          },
          {
            path: PARTNER_ROUTE_PATH,
            loadChildren: () => import('@roadrecord/partner/us').then(m => m.PartnerModule),
            data: {
              type: PartnerTypeEnum.PARTNER,
            },
            canLoad: [AuthGuard],
            canActivate: [AuthGuard, HasAccesMenuItemGuard],
          },
          {
            path: CHARGING_STATION_ROUTE_PATH,
            loadChildren: () => import('@roadrecord/partner/us').then(m => m.PartnerModule),
            data: {
              type: PartnerTypeEnum.CHARGING_STATION,
            },
            canLoad: [AuthGuard],
            canActivate: [AuthGuard, HasAccesMenuItemGuard],
          },
          {
            path: HEAD_OFFICE_OR_SITE_PATH,
            loadChildren: () => import('@roadrecord/partner/us').then(m => m.PartnerModule),
            data: {
              type: PartnerTypeEnum.HEAD_OFFICE_OR_SITE,
            },
            canLoad: [AuthGuard],
            canActivate: [AuthGuard, HasAccesMenuItemGuard],
          },
          {
            path: PARTNER_DISTANCE_ROUTE_PATH,
            loadChildren: () => import('@roadrecord/partner-distance/common').then(m => m.PartnerDistanceModule),
            canLoad: [AuthGuard],
            canActivate: [AuthGuard, HasAccesMenuItemGuard],
          },
          {
            path: VEHICLE_ROUTE_PATH,
            loadChildren: () => import('@roadrecord/vehicle/us').then(m => m.VehicleUsModule),
            canLoad: [AuthGuard],
            canActivate: [AuthGuard, HasAccesMenuItemGuard],
          },
          {
            path: 'vehicle-partner-join',
            loadChildren: () => import('@roadrecord/vehicle-partner-join/common').then(m => m.VehiclePartnerJoinModule),
            canLoad: [AuthGuard],
            canActivate: [AuthGuard, HasAccesMenuItemGuard],
          },
          {
            path: 'partner-vehicle-join',
            loadChildren: () => import('@roadrecord/partner-vehicle-join/common').then(m => m.PartnerVehicleJoinModule),
            canLoad: [AuthGuard],
            canActivate: [AuthGuard, HasAccesMenuItemGuard],
          },
          {
            path: DAILY_PERMANENT_DESTINATION_PATH,
            loadChildren: () => import('@roadrecord/daily-permanent-destination').then(m => m.DailyPermanentDestinationModule),
            canLoad: [AuthGuard],
            canActivate: [AuthGuard, HasAccesMenuItemGuard],
          },
          {
            path: STANDARD_MILEAGE_RATE_ROUTE_PATH,
            loadChildren: () => import('@roadrecord/standard-mileage-rate').then(m => m.StandardMileageRateModule),
            canLoad: [AuthGuard],
            canActivate: [AuthGuard, HasAccesMenuItemGuard],
          },
        ],
      },
      {
        path: MONTH_ACTIVITIES_PAGE_PATH,
        canActivateChild: [MustFirstStepGuard],
        children: [
          { path: '', redirectTo: SPECIAL_DAYS_ROUTE_PATH, pathMatch: 'full' },
          {
            path: EXPENSE_ROUTH_PATH,
            loadChildren: () => import('@roadrecord/expense').then(m => m.ExpenseModule),
            canLoad: [AuthGuard],
            canActivate: [AuthGuard, HasAccesMenuItemGuard],
          },
          {
            path: SPECIAL_DAYS_ROUTE_PATH,
            loadChildren: () => import('@roadrecord/special-days').then(m => m.SpecialDaysModule),
            canLoad: [AuthGuard],
            canActivate: [AuthGuard, HasAccesMenuItemGuard],
          },
          {
            path: FINALIZATION_OF_ROUTE_ROUTE_PATH,
            loadChildren: () => import('@roadrecord/finalization-of-route/us').then(m => m.FinalizationOfRouteUsModule),
            canLoad: [AuthGuard],
            canActivate: [AuthGuard, HasAccesMenuItemGuard],
          },
          {
            path: RECOMMENDATION_ROUTE_PATH,
            loadChildren: () => import('@roadrecord/recommendation').then(m => m.RecommendationModule),
            canLoad: [AuthGuard],
            canActivate: [AuthGuard],
          },
          {
            path: REPORT_PRINT_PATH,
            loadChildren: () => import('@roadrecord/report-print/common').then(m => m.ReportPrintModule),
            canLoad: [AuthGuard],
            canActivate: [AuthGuard, HasAccesMenuItemGuard],
          },
          {
            path: REPORT_SITE_PATH,
            loadChildren: () => import('@roadrecord/report').then(m => m.ReportModule),
            canLoad: [AuthGuard],
            canActivate: [AuthGuard],
          },
          {
            path: MILEAGE_ROUTE_PATH,
            loadChildren: () => import('@roadrecord/mileage/us').then(m => m.MileageUsModule),
            canLoad: [AuthGuard],
            canActivate: [AuthGuard, HasAccesMenuItemGuard],
          },
          // { path: 'reportarchive', component: ReportarchiveComponent },
          // { path: 'changepassword', component: ChangePasswordComponent },

          {
            path: FUELING_ROUTE_PATH,
            loadChildren: () => import('@roadrecord/fueling/common').then(m => m.FuelingModule),
            canLoad: [AuthGuard],
            canActivate: [AuthGuard, HasAccesMenuItemGuard],
          },
        ],
      },
      {
        path: USER_ROUTE_PATH,
        canActivateChild: [MustFirstStepGuard],
        children: [
          {
            path: CHANGE_PASSWORD,
            loadChildren: () => import('@roadrecord/user/common').then(m => m.ChangePasswordModule),
            canLoad: [AuthGuard],
            canActivate: [AuthGuard],
          },
          {
            path: 'integrations',
            loadChildren: () => import('@roadrecord/integrations').then(m => m.IntegrationsModule),
            canLoad: [AuthGuard],
            canActivate: [AuthGuard],
          },
          {
            path: SUBSCRIPTION_DATA,
            loadChildren: () => import('@roadrecord/user/common').then(m => m.SubscriptionDataModule),
            canLoad: [AuthGuard],
            canActivate: [AuthGuard],
          },
          {
            path: COMPANY_MEMBER,
            loadChildren: () => import('@roadrecord/user/common').then(m => m.CompanyMemberModule),
            canLoad: [AuthGuard],
            canActivate: [AuthGuard],
          },
          {
            path: APPLICATION_SETTINGS,
            loadChildren: () => import('@roadrecord/user/common').then(m => m.ApplicationSettingsModule),
            canLoad: [AuthGuard],
            canActivate: [AuthGuard],
          },
        ],
      },
      {
        path: USER_ROUTE_PATH,
        children: [
          {
            path: PRICE_SITE_PATH,
            loadChildren: () => import('@roadrecord/user/common').then(m => m.PriceModule),
            canLoad: [AuthGuard],
            canActivate: [AuthGuard],
          },
          {
            path: TRIAL_TIME_ROUTE_PATH,
            loadChildren: () => import('@roadrecord/user/common').then(m => m.ExpiredTrialTimeModule),
            canLoad: [AuthGuard],
            canActivate: [AuthGuard],
          },
        ],
      },
    ],
  },
  {
    path: 'mobile',
    loadChildren: () => import('@roadrecord/mobile-detect').then(m => m.MobileDetectModule),
  },
  {
    path: '404',
    loadChildren: () => import('@roadrecord/error404').then(m => m.Error404Module),
    canActivate: [MobileDetectGuard],
  },
  { path: '**', redirectTo: '/404' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      // enableTracing: true,
      useHash: true,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}

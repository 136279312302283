<ng-template #content>
  <mat-card [attr.id]="'registration-container'" class="rr-card2 pl-4 pr-4" style="max-width: 500px">
    <mat-progress-bar *ngIf="loading$ | async" color="warn" mode="indeterminate"></mat-progress-bar>
    <mat-card-content>
      <div class="registration-header">
        <div class="d-flex">
          <img class="navallobadge" src="/assets/images/navallobadge/nav-allo-emblema-websitera.svg" alt="Navallo" />
        </div>
        <div class="registration-header-text-container">
          <span class="registration-title">{{ 'USER.REGISTER.TRY_IT_PART_1' | transloco }}</span>
          <span class="registration-title-text-1">{{ 'USER.REGISTER.TRY_IT_PART_2' | transloco }}</span>
          <hr class="registration-title-hr" />
          <span class="registration-title-text-2" [innerHTML]="'USER.REGISTER.TRY_IT_AFTER_TEXT' | transloco | sanitizeHtml"> </span>
        </div>
      </div>
      <rr-social-buttons-card
        *ngIf="registrationType === registrationEnumType.REG_TYPE_B"
        [form]="form"
        [mode]="'REGISTRATION'"
        buttonColor="accent"
        buttonType="mat-stroked-button"
        [registrationType]="registrationType"
        (clickWithEmail)="onClickLoginWithEmail()"
        withEmailIcon="login-variant"
        withEmailButtonTranslateKey="USER.SOCIAL.LOGIN_WITH_EMAIL"
      >
      </rr-social-buttons-card>
      <form
        [attr.id]="formIdentifier"
        [formGroup]="form"
        fxLayout="column"
        fxLayoutAlign="center stretch"
        class="overflow-hidden"
        novalidate
      >
        <div>
          <div>
            <mat-form-field class="wid100 x--email" appearance="outline">
              <mat-label>{{ 'USER.REGISTER.EMAIL' | transloco }}</mat-label>
              <input
                #email
                [placeholder]="'USER.REGISTER.EX_EMAIL' | transloco"
                formControlName="email"
                matInput
                name="email"
                required
                type="email"
              />
              <mat-icon class="mat-icon" matSuffix>email</mat-icon>
              <mat-error>
                <rr-validation-messages
                  [errors]="emailControl?.errors"
                  [messages]="emailControlValidationMessages"
                ></rr-validation-messages>
              </mat-error>
              <mat-hint>{{ 'USER.REGISTER.HINT.EMAIL' | transloco }}</mat-hint>
            </mat-form-field>

            <mat-form-field class="wid100 x--password" appearance="outline">
              <mat-label>{{ 'USER.REGISTER.PASSWORD' | transloco }}</mat-label>
              <input
                [placeholder]="'USER.REGISTER.PASSWORD' | transloco"
                formControlName="password"
                matInput
                name="password"
                required
                [type]="passwordType"
              />
              <mat-hint *ngIf="passwordControl.invalid || passwordControl?.value?.length === 0" class="password-hint">
                {{ 'COMMON.VALIDATION.PASSWORD_PATTERN' | transloco }}
              </mat-hint>
              <button tabindex="-1" type="button" mat-icon-button matSuffix (click)="changeVisibilityPassword()" rrPreventClick>
                <mat-icon
                  class="visible-icon pl-2 mat-icon"
                  [matTooltip]="(!visiblePassword ? 'COMMON.TOOLTIP.PASSWORD_SHOW' : 'COMMON.TOOLTIP.PASSWORD_HIDE') | transloco"
                  matTooltipPosition="left"
                  [svgIcon]="!visiblePassword ? 'eye' : 'eye-off'"
                >
                </mat-icon>
              </button>
              <mat-error>
                <rr-validation-messages
                  [errors]="passwordControl?.errors"
                  [messages]="passwordControlValidationMessages"
                ></rr-validation-messages>
              </mat-error>
            </mat-form-field>
          </div>
        </div>

        <div
          class="checkbox-area-wrapper pl-3"
          [ngStyle]="loadCompanyRegistrationComplete ? { 'margin-top': '40px' } : undefined"
          fxLayout="column"
          fxLayoutAlign="start start"
        >
          <mat-checkbox class="magazine-cb" formControlName="magazineSubscribe" name="magazineSubscribe">
            {{ 'USER.REGISTER.SUBSCRIBE_MAGAZINE' | transloco }}
          </mat-checkbox>
        </div>

        <div class="w-100 pt-3">
          <rr-submit-button-with-error
            class="w-100"
            [disabled]="form.disabled || (form.submitted && form.invalid)"
            [formSubmitted]="form.submitted"
            [formInvalid]="form.invalid"
            [buttonLabel]="submitLabel"
            invalidTooltip="COMMON.VALIDATION.BUTTON"
            (clickButton)="onSubmit()"
            [gaPrefix]="registrationType === registrationEnumType.REG_TYPE_A ? 'registration-a' : 'registration-b'"
            [buttonIcon]="'person_add_alt_1'"
          ></rr-submit-button-with-error>
        </div>
      </form>
      <rr-social-buttons-card
        *ngIf="registrationType === registrationEnumType.REG_TYPE_A"
        [form]="form"
        [mode]="'REGISTRATION'"
        buttonColor="accent"
        buttonType="mat-stroked-button"
        [registrationType]="registrationType"
        (clickWithEmail)="onClickLoginWithEmail()"
        withEmailIcon="login-variant"
        withEmailButtonTranslateKey="USER.SOCIAL.LOGIN_WITH_EMAIL"
      >
      </rr-social-buttons-card>

      <div class="d-flex justify-content-center w-100 text-center pt-2">
        <span class="w-100 registration-datas-text">
          {{ 'USER.REGISTER.YOUR_DATAS' | transloco }}
        </span>
      </div>

      <div class="terms-and-privacy" [innerHTML]="'USER.REGISTER.TERMS_AND_PRIVACY' | transloco | sanitizeHtml"> </div>

      <button mat-button class="w-100 login-existing-user-button" [disabled]="form.disabled" (click)="onClickLoginWithEmail()">
        <span class="button-text">{{ 'USER.SOCIAL.LOGIN_WITH_EMAIL' | transloco }}</span>
        <mat-icon class="button-icon mat-icon-white" svgIcon="login-variant"></mat-icon>
      </button>
    </mat-card-content>
  </mat-card>
  <rr-unauthenticated-footer></rr-unauthenticated-footer>
</ng-template>

<div class="scroll">
  <div *ngIf="!isMobileOrTablet; else mobileOrTabletTpl" class="wrapper-component background-image">
    <div fxFlex.gt-md fxLayout="row" fxLayoutAlign="center center">
      <div class="wid100" fxLayout="column" fxLayoutAlign="center center">
        <ng-container *ngTemplateOutlet="content"></ng-container>
      </div>
    </div>
  </div>
  <ng-template #mobileOrTabletTpl>
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </ng-template>
</div>
<re-captcha
  #captchaRef="reCaptcha"
  *ngIf="useCaptcha"
  [siteKey]="reCaptchaKey"
  size="invisible"
  (resolved)="onResolveCaptcha($event)"
></re-captcha>
